import React, { useState,useEffect } from 'react';
// import logo from '../../assets/logo.svg';
import './App.css';
import axios from 'axios';

function App() {

  useEffect(()=>{
    // Check if user is logged in by fetching token from cookie
    const getProfile = async () => {
  try {
    const { data } =  await axios.get(`https://backend-test-cookie-1.onrender.com/profile`, {
      withCredentials: true,
    });

    console.log(data)
  } catch (error) {
    console.log(error);
  }
};
  getProfile() 
const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
console.log(document.cookie,"see from dash")
if (token) {
  // Verify the token or decode it to extract user details
  // Proceed with dashboard functionality
  console.log("hai",token)
} else {
  // Redirect to login or perform necessary actions for logged-out state
   // window.location.href = 'https://6565dcc3ad3d816ba834db1a--guileless-dusk-82c1c7.netlify.app/';
  window.location.href = 'https://frontend-project2.accredian.com/';
  // alert("not logged in ")
}

  },[])
  const [idToken, setIdToken] = useState('')
  const [profile, setProfile] = useState({})

  const [explorerPath, setExplorerPath] = useState('') // Current file explorer path
  const [doRefresh, refreshExplorer] = useState(true)

  const [fileUploadOpen, setFileUploadOpen] = useState(false)
  const [folderCreatorOpen, setFolderCreatorOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://localhost:5000/upload', formData);
      // console.log(response)
      setFileUrl(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const logout=async ()=>{
    const response = await axios.post('http://localhost:5000/logout', {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: true, // Important for sending cookies
    });
    const data = response.data;
    if (data.success) {
      // setlogin(false)
      window.location.href = 'https://frontend-project2.accredian.com';
    }
  }
  function deleteCookie(name, domain) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    window.location.href = 'https://frontend-project2.accredian.com';
  }
  return (
    <div className="App">
      

      <div>
      <h1>user is logged in </h1>
      <h1>File Upload to Google Cloud Storage</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      <button onClick={()=>{deleteCookie('token','accredian.com')}}>logout</button>
      {fileUrl && (
        <div>
          <p>File URL:</p>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {fileUrl}
          </a>
        </div>
      )}
    </div>

      {/* <nav>
        <Sidebar
          profile={profile}
          openFileUpload={() => setFileUploadOpen(true)}
          openFolderCreator={() => setFolderCreatorOpen(true)}
          openSettings={() => setSettingsOpen(true)}
        />
      </nav> */}
      {/* <Auth setIdToken={(t) => {
        api.idToken = t
        setIdToken(t)
      }} setProfile={setProfile}/> */}
      {/* <section className='app-content'>
        <FileExplorer
          idToken={idToken}
          profile={profile}
          setExplorerPath={setExplorerPath}
          doRefresh={doRefresh}
          didRefresh={() => refreshExplorer(false)}
        />
      </section>
      <FileUploadModal
        open={fileUploadOpen}
        closeModal={() => {setFileUploadOpen(false); refreshExplorer(true)}}
        path={explorerPath}
        onSuccess={() => {setFileUploadOpen(false); refreshExplorer(true)}}
      />
      <FolderCreationModal
        open={folderCreatorOpen}
        closeModal={() => setFolderCreatorOpen(false)}
        path={explorerPath}
        onSuccess={() => {setFolderCreatorOpen(false); refreshExplorer(true)}}
      />
      <SettingsModal
        open={settingsOpen}
        closeModal={() => setSettingsOpen(false)}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </div>
  );
}

export default App;
